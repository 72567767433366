document.addEventListener("DOMContentLoaded", function(event) {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark')
    } else {
        document.documentElement.classList.remove('dark')
    }

    // Whenever the user explicitly chooses light mode
    var lightButtons = document.querySelectorAll('.apply_light');
    lightButtons.forEach(el => el.addEventListener('click', event => {
        event.preventDefault();
        localStorage.theme = 'light';
        window.location.reload();
    }));

    var darkButtons = document.querySelectorAll('.apply_dark');
    darkButtons.forEach(el => el.addEventListener('click', event => {
        event.preventDefault();
        localStorage.theme = 'dark';
        window.location.reload();
    }));

    // Whenever the user explicitly chooses to respect the OS preference
    var autoButtons = document.querySelectorAll('.apply_auto');
    autoButtons.forEach(el => el.addEventListener('click', event => {
        event.preventDefault();
        localStorage.removeItem('theme');
        window.location.reload();
    }));
});
